/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container class="grid">
              <h5 class="dragBlock font-semibold cursor-pointer">Confidentiality</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">Availability</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">Integrity</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row my-12 p-3">
                <div class="vx-col w-1/3"></div>
                <div class="vx-col w-1/3">
                  <h1 class="text-center mb-2">Unauthorized Access is<br /><span class="text-warning font-semibold">Prevented</span></h1>
                  <vue-draggable-container id="input0" class="answerStandard dropzone dropzoneStandard answer-bg">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-1/3"></div>
              </div>
              <div class="vx-row my-12 p-3">
                <div class="vx-col w-1/3">
                  <h1 class="text-center mb-2">Data Cannot Be<br /><span class="text-warning font-semibold">Unavailable</span></h1>
                  <vue-draggable-container id="input1" class="answerStandard dropzone dropzoneStandard answer-bg">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-1/3"></div>
                <div class="vx-col w-1/3">
                  <h1 class="text-center mb-2">Data Cannot Be<br /><span class="text-warning font-semibold">Modified</span></h1>
                  <vue-draggable-container id="input2" class="answerStandard dropzone dropzoneStandard answer-bg">
                  </vue-draggable-container>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },

    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'Confidentiality') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Availability') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'Integrity') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
